<template>
    <v-card flat>
        <v-card-title class="mb-4">
            <span class="primary--text font-weight-bold">Add New Snapshot</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <h4 class="primary--text">Name of the snapshot</h4>
                <v-text-field
                    :disabled="snapshotCreationLoading === true"
                    label="Provide a snapshot name here"
                    v-model="snapshotName"
                    :rules="[rules.nonEmpty]"
                    required
                >
                </v-text-field>
                <h4 class="primary--text mt-4">Description of recently completed work phase</h4>
                <v-textarea
                    v-model="snapshotDescription"
                    label="Provide a description of your snapshot"
                    rows="4"
                    auto-grow
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="snapshotCreationLoading === true"
                ></v-textarea>
                <h4 class="primary--text mt-4">Description of the next work phase</h4>
                <v-textarea
                    v-if="provideNextStageDescription"
                    v-model="nextStageDescription"
                    label="Provide a description of what you will be working on in the next stage"
                    rows="3"
                    auto-grow
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="snapshotCreationLoading === true"
                ></v-textarea>
                <v-checkbox
                    @change="provideNextStageDescription = !provideNextStageDescription"
                    class="pb-3"
                    label="No work is expected in the next stage"
                    color="secondary"
                    value="red"
                    hide-details
                    :disabled="snapshotCreationLoading === true"
                ></v-checkbox>
            </v-form>
        </v-card-text>

        <v-card-actions class="mt-4">
            <div style="width:100%" class="d-flex align-center flex-column justify-center">
                <div>
                    <v-btn
                        class="mr-1"
                        :loading="snapshotCreationLoading"
                        :disabled="!valid || snapshotCreationLoading"
                        @click="addNewSnapshot()"
                        color="secondary"
                    >
                        <v-icon small>add</v-icon>Add Snapshot
                    </v-btn>
                    <v-btn
                        :disabled="snapshotCreationLoading"
                        color="secondary"
                        outlined
                        text
                        :to="{
                            name: 'snapshot-overview',
                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                        }"
                        >cancel</v-btn
                    >
                </div>
                <div v-if="snapshotCreationError === true" class="mt-5">
                    <v-alert color="error" icon="warning" text max-width="500">
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Snapshot creation error</span>
                            {{ snapshotCreationErrorContent }}
                        </div>
                    </v-alert>
                </div>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { makeShortId } from '@/utils'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'

export default {
    mixins: [createQuickAndDetailedSnapshots, enumsData, snapshotEnums],
    data() {
        return {
            snapshotName: '',
            snapshotDescription: '',
            provideNextStageDescription: true,
            nextStageDescription: '',
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            },
            valid: false
        }
    },
    computed: {
        ...mapGetters('snapshotStore', ['developmentSnapshot'])
    },
    methods: {
        generateShortId(longId) {
            return makeShortId(longId)
        },
        updateShortId(newShortId) {
            this.$data.snapshotShortName = newShortId
        },
        addNewSnapshot: function() {
            const patchBody = {
                description: this.$data.nextStageDescription ? this.$data.nextStageDescription : 'No description given'
            }
            this.$axios.patch('/snapshots/' + this.developmentSnapshot.snid, patchBody)
            const description = this.$data.snapshotDescription
            const longId = this.$data.snapshotName
            const shortId = this.generateShortId(this.$data.snapshotName)
            this.addSnapshot(this.snapshotTypes.DETAILED_SNAPSHOT, longId, shortId, description)
        }
    },
    watch: {
        snapshotName: function(nextValue, prevValue) {
            this.$data.snapshotShortName = this.generateShortId(nextValue)
        }
    }
}
</script>
