export const enumsData = {
    data() {
        return {
            spaceTypes: {
                EDUCATION_SPACE: 1,
                RESEARCH_SPACE: 2,
                VENDOR_SPACE: 0,
                KNOWLEDGE_BASE_SPACE: 3
            },
            roleTypes: {
                ORG_MANAGER: 'MANAGER',
                ORG_FACULTY: 'FACULTY',
                SPACE_ADMIN: 'SPACE_ADMIN',
                SPACE_MEMBER: 'None',
                INSTANCE_EDITOR: 'EDITOR',
                INSTANCE_VIEWER: 'VIEWER'
            },
            instanceFixedNames: {
                MASTER_INSTANCE: 'Master',
                DISTRIBUTED_INSTANCE: 'Distributed'
            },
            invitationRoleTypes: {
                INSTANCE_ROLE: 'InstanceRoleType',
                SPACE_ROLE: 'SpaceRoleType',
                ORG_ROLE: 'OrgRoleType'
            },
            orgTokenScopes: {
                MANAGER: 0,
                FACULTY: 10,
                MEMBER: 30
            },
            orgTokenTypes: {
                XPRESSFEED: 0
            },
            folderTypesLabels: {
                WORKSPACE_FILES: 'files',
                PERSONAL_FILES: 'home',
                LIBRARY_FILES: 'lib'
            },
            taskStatusTypes: {
                REJECTED: 0,
                CREATED: 1,
                QUEUED: 2,
                RUNNING: 3,
                COMPLETED: 4,
                FAILED: 5
            }
        }
    },
    methods: {
        checkEditorRole(role) {
            if (role && role.toLowerCase() === this.$data.roleTypes.INSTANCE_EDITOR.toLowerCase()) {
                return true
            } else {
                return false
            }
        },
        checkDistributedInstance(instance) {
            if (instance && instance.toLowerCase() === this.$data.instanceFixedNames.DISTRIBUTED_INSTANCE.toLowerCase()) {
                return true
            } else {
                return false
            }
        }
    }
}
