export const snapshotEnums = {
    data() {
        return {
            snapshotTypes: {
                QUICK_SNAPSHOT: 20,
                AUTO_SNAPSHOT: 30,
                DETAILED_SNAPSHOT: 10
            },
            snapshotBackupStatusOptions: {
                CREATING_SNAPSHOT: 'creating'
            }
        }
    },
    methods: {
        getSnapshotStatusIcon: function(snapshotData) {
            if (!snapshotData.backup_location) {
                return 'mdi-lock-off'
            } else if (snapshotData.backup_location === this.snapshotBackupStatusOptions.CREATING_SNAPSHOT) {
                return 'mdi-lock-clock'
            } else {
                return 'mdi-lock-check'
            }
        },
        getSnapshotStatusDescription: function(snapshotData) {
            if (!snapshotData.filesystem_prefix && !snapshotData.backup_location) {
                return 'This snapshot has no backup and its files cannot be accessed from the web interface. Restore to the Current State to work with the files in this snapshot.'
            } else if (snapshotData.filesystem_prefix && !snapshotData.backup_location) {
                return 'This snapshot has no backup.'
            } else if (snapshotData.filesystem_prefix && snapshotData.backup_location === this.snapshotBackupStatusOptions.CREATING_SNAPSHOT) {
                return 'A backup is being created for this snapshot.'
            } else if (snapshotData.filesystem_prefix && snapshotData.backup_location) {
                return 'A backup exists for this snapshot'
            } else if (!snapshotData.filesystem_prefix && snapshotData.backup_location === this.snapshotBackupStatusOptions.CREATING_SNAPSHOT) {
                return 'A backup is being created for this snapshot. Files in this snapshot are in cold state and cannot be accessed from the web interface, please restore the snapshot to the Current State to work with its files.'
            } else if (!snapshotData.filesystem_prefix && snapshotData.backup_location) {
                return 'Files in this snapshot are in cold state and cannot be accessed from the web interface, please restore the snapshot to the Current State to work with its files.'
            }
        }
    }
}
